
/**
 * @roxi/routify 2.18.4
 * File generated Mon Jan 27 2025 00:19:25 GMT+0000 (Coordinated Universal Time)
 */

export const __version = "2.18.4"
export const __timestamp = "2025-01-27T00:19:25.509Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports


//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isIndex": true,
      "isPage": true,
      "path": "/index",
      "id": "_index",
      "component": () => import('../src/pages/index.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "path": "/shop/index",
          "id": "_shop_index",
          "component": () => import('../src/pages/shop/index.svelte').then(m => m.default)
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isPage": true,
              "path": "/shop/item/:id",
              "id": "_shop_item__id",
              "component": () => import('../src/pages/shop/item/[id].svelte').then(m => m.default)
            }
          ],
          "path": "/shop/item"
        }
      ],
      "path": "/shop"
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isPage": true,
                  "path": "/teams/:team/filter/:member",
                  "id": "_teams__team_filter__member",
                  "component": () => import('../src/pages/teams/[team]/filter/[member].svelte').then(m => m.default)
                }
              ],
              "path": "/teams/:team/filter"
            },
            {
              "isIndex": true,
              "isPage": true,
              "path": "/teams/:team/index",
              "id": "_teams__team_index",
              "component": () => import('../src/pages/teams/[team]/index.svelte').then(m => m.default)
            }
          ],
          "path": "/teams/:team"
        }
      ],
      "path": "/teams"
    }
  ],
  "path": "/"
}


export const {tree, routes} = buildClientTree(_tree)

